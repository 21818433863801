import React, { useEffect, useContext, useState, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import "./QueryDashboard.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const API_BASE_URL = process.env.REACT_APP_API_URL;
// NeumorphicToggle Component
const NeumorphicToggle = ({ id, name, checked, onChange, label }) => {
  return (
    <div className="toggler">
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        value="1"
      />
      <label htmlFor={id}>
        <svg
          className="toggler-on"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <polyline
            className="path check"
            points="100.2,40.2 51.5,88.8 29.8,67.5"
          ></polyline>
        </svg>
        <svg
          className="toggler-off"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <line
            className="path line"
            x1="34.4"
            y1="34.4"
            x2="95.8"
            y2="95.8"
          ></line>
          <line
            className="path line"
            x1="95.8"
            y1="34.4"
            x2="34.4"
            y2="95.8"
          ></line>
        </svg>
      </label>
      <span className="toggle-label">{label}</span>
    </div>
  );
};

const QueryDashboard = () => {
  const { userDetails } = useContext(AuthContext);

  const [searchUser, setSearchUser] = useState(""); // Search query for Assigned To dropdown
  const [filteredUsers, setFilteredUsers] = useState([]); // Filtered user list
  const [users, setUsers] = useState([]); // Full user list
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);

  const [tickets, setTickets] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalTickets, setTotalTickets] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const pageRef = useRef(1);
  const effectRan = useRef(false);

  const [openAssignedDropdowns, setOpenAssignedDropdowns] = useState({});
  const [openStatusDropdowns, setOpenStatusDropdowns] = useState({});

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [currentViewTotalTickets, setCurrentViewTotalTickets] = useState(0);

  const [selectedView, setSelectedView] = useState("All tickets");

  const [messageContent, setMessageContent] = useState("");
  const [sendViaEmail, setSendViaEmail] = useState(false);
  const [sendViaWhatsApp, setSendViaWhatsApp] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [messagePage, setMessagePage] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const messageHistoryRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  // New state variables
  const [regions, setRegions] = useState([]); // List of regions
  const [selectedRegion, setSelectedRegion] = useState(null); // Selected region ID
  const [isMyQueriesActive, setIsMyQueriesActive] = useState(false); // My Queries toggle

  // Handler to send message
  const handleSendMessage = async () => {
    if (!selectedTicket) {
      alert("Please select a ticket first.");
      return;
    }
    if (!messageContent.trim()) {
      alert("Please enter a message.");
      return;
    }
    if (!sendViaEmail && !sendViaWhatsApp) {
      alert("Please select at least one method to send the message.");
      return;
    }
    setIsSending(true);
    try {
      const res = await axios.post(
        `${API_BASE_URL}/tickets/${selectedTicket.ticket_id}/send-message`,
        {
          content: messageContent,
          via_email: sendViaEmail,
          via_whatsapp: sendViaWhatsApp,
          sender: userDetails?.email,
          sender_name: userDetails?.name,
        }
      );

      // Update message history
      setMessageHistory([...messageHistory, res.data.newMessage]);
      setMessageContent("");
      setSendViaEmail(false);
      setSendViaWhatsApp(false);

      // Scroll to bottom
      if (messageHistoryRef.current) {
        messageHistoryRef.current.scrollTop =
          messageHistoryRef.current.scrollHeight;
      }
    } catch (err) {
      console.error("Error sending message:", err);
      alert("Failed to send message.");
    } finally {
      setIsSending(false);
    }
  };

  // Fetch message history when a ticket is selected
  useEffect(() => {
    const fetchMessageHistory = async () => {
      if (selectedTicket) {
        // Reset message history state
        setMessageHistory([]);
        setMessagePage(1);
        setHasMoreMessages(true);
        setIsLoadingMessages(true);

        try {
          const res = await axios.get(
            `${API_BASE_URL}/tickets/${selectedTicket.ticket_id}/messages?page=1&limit=10`
          );
          const totalMessages = res.data.totalMessages;
          const messages = res.data.messages;

          // Reverse messages to display from oldest to newest
          const reversedMessages = messages.reverse();

          setMessageHistory(reversedMessages);

          // Scroll to bottom after messages are loaded
          if (messageHistoryRef.current) {
            setTimeout(() => {
              messageHistoryRef.current.scrollTop =
                messageHistoryRef.current.scrollHeight;
            }, 0);
          }

          // Check if there are more messages to load
          if (messages.length >= totalMessages) {
            setHasMoreMessages(false);
          }
        } catch (err) {
          console.error("Error fetching message history:", err);
          setMessageHistory([]);
          setHasMoreMessages(false);
        } finally {
          setIsLoadingMessages(false);
        }
      } else {
        setMessageHistory([]);
        setHasMoreMessages(false);
      }
    };

    fetchMessageHistory();
  }, [selectedTicket]);

  const handleMessageScroll = () => {
    if (
      messageHistoryRef.current.scrollTop === 0 &&
      hasMoreMessages &&
      !isFetchingMessages
    ) {
      fetchMoreMessages();
    }
  };

  const fetchMoreMessages = async () => {
    if (isFetchingMessages || !hasMoreMessages) return;
    setIsFetchingMessages(true);

    // Save the current scroll position
    const scrollPosition =
      messageHistoryRef.current.scrollHeight -
      messageHistoryRef.current.scrollTop;

    const nextPage = messagePage + 1;
    try {
      const res = await axios.get(
        `${API_BASE_URL}/tickets/${selectedTicket.ticket_id}/messages?page=${nextPage}&limit=10`
      );
      const totalMessages = res.data.totalMessages;
      const messages = res.data.messages;

      // Reverse messages to display from oldest to newest
      const reversedMessages = messages.reverse();

      setMessageHistory((prevMessages) => [
        ...reversedMessages,
        ...prevMessages,
      ]); // Prepend messages
      setMessagePage(nextPage);

      // Adjust scroll to maintain position
      setTimeout(() => {
        messageHistoryRef.current.scrollTop =
          messageHistoryRef.current.scrollHeight - scrollPosition;
      }, 0);

      // Check if all messages have been loaded
      if (messageHistory.length + messages.length >= totalMessages) {
        setHasMoreMessages(false);
      }
    } catch (err) {
      console.error("Error fetching more messages:", err);
      setHasMoreMessages(false);
    } finally {
      setIsFetchingMessages(false);
    }
  };

  const statusMap = {
    "All tickets": null, // no status filter
    "Unsolved tickets": "In Progress",
    "Open tickets": "Open",
    "Solved tickets": "Closed",
    "Deleted tickets": "Deleted",
  };
  const views = [
    "All tickets",
    "Unsolved tickets",
    "Open tickets",
    "Solved tickets",
    "Deleted tickets",
  ];

  const [ticketCounts, setTicketCounts] = useState({});

  useEffect(() => {
    if (effectRan.current === false) {
      fetchTickets();
      fetchUsers();
      fetchTicketCounts();
      fetchRegions(); // Fetch regions on mount
      effectRan.current = true;
    }
  }, []);

  const fetchTickets = async (reset = false, searchParam) => {
    if (isFetching) {
      // Prevent duplicate calls
      return;
    }
    setIsFetching(true);
    try {
      let currentPage = pageRef.current;
      if (reset) {
        currentPage = 1;
      }
      const statusValue = statusMap[selectedView];
      let url = `${API_BASE_URL}/tickets?page=${currentPage}&limit=10`;
      if (statusValue) {
        url += `&status=${encodeURIComponent(statusValue)}`;
      }
      const search =
        typeof searchParam !== "undefined"
          ? searchParam.trim()
          : searchKeyword.trim();
      if (search) {
        url += `&search=${encodeURIComponent(search)}`;
      }
      if (selectedRegion) {
        url += `&region_id=${encodeURIComponent(selectedRegion)}`;
      }
      if (isMyQueriesActive) {
        url += `&assigned_to=${encodeURIComponent(userDetails?.email)}`;
      }
      const res = await fetch(url);
      const data = await res.json();

      if (reset) {
        setTickets([]);
        pageRef.current = 1;
        setHasMore(true);
      }

      if (data.tickets.length === 0) {
        setHasMore(false);
      } else {
        setTickets((prevTickets) => {
          const newTickets = reset
            ? data.tickets
            : [...prevTickets, ...data.tickets];

          // Update hasMore based on totalTickets
          if (newTickets.length >= data.totalCount) {
            setHasMore(false);
          }

          return newTickets;
        });

        pageRef.current = currentPage + 1;
        setCurrentViewTotalTickets(data.totalCount);
      }
    } catch (err) {
      console.error("Error fetching tickets:", err);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchTicketCounts = async () => {
    try {
      let url = `${API_BASE_URL}/tickets/counts`;
      const params = [];
      if (selectedRegion) {
        params.push(`region_id=${encodeURIComponent(selectedRegion)}`);
      }
      if (isMyQueriesActive) {
        params.push(`assigned_to=${encodeURIComponent(userDetails?.email)}`);
      }
      if (params.length > 0) {
        url += "?" + params.join("&");
      }
      const res = await fetch(url);
      const data = await res.json();
      setTicketCounts(data.counts);
      setTotalTickets(data.totalTickets);
    } catch (err) {
      console.error("Error fetching ticket counts:", err);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      pageRef.current = 1;
      setHasMore(true);
      setTickets([]);
      fetchTickets(true, searchKeyword);
      fetchTicketCounts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    searchKeyword,
    selectedView,
    selectedRegion,
    isMyQueriesActive,
    userDetails?.email,
  ]);

  const fetchUsers = async () => {
    try {
      const res = await fetch(
        `${API_BASE_URL}/users?accessiblePage=query-dashboard`
      );
      const data = await res.json();
      setUsers(data.users);
      setFilteredUsers(data.users);
    } catch (err) {
      console.error("Error fetching users:", err);
    }
  };

  // Fetch regions
  const fetchRegions = async () => {
    try {
      const res = await fetch(`${API_BASE_URL}/regions`);
      const data = await res.json();
      setRegions(data);
    } catch (err) {
      console.error("Error fetching regions:", err);
    }
  };

  useEffect(() => {
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(searchUser.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchUser, users]);

  const handleAssignChange = async (ticketId, assignedToEmail) => {
    try {
      const res = await fetch(`${API_BASE_URL}/tickets/${ticketId}/assign`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ assigned_to: assignedToEmail }),
      });
      const data = await res.json();
      if (res.ok) {
        // Update the tickets state
        setTickets((prevTickets) =>
          prevTickets.map((ticket) =>
            ticket.ticket_id === ticketId
              ? { ...ticket, assigned_to_name: data.ticket.assigned_to_name }
              : ticket
          )
        );
        setOpenAssignedDropdowns((prevState) => ({
          ...prevState,
          [ticketId]: false,
        }));
      } else {
        console.error("Error updating assigned_to:", data.error);
      }
    } catch (err) {
      console.error("Error updating assigned_to:", err);
    }
  };

  const handleStatusChange = async (ticketId, statusValue) => {
    try {
      const res = await fetch(`${API_BASE_URL}/tickets/${ticketId}/status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: statusValue }),
      });
      const data = await res.json();
      if (res.ok) {
        const updatedTicket = data.ticket;
        const oldTicket = tickets.find(
          (ticket) => ticket.ticket_id === ticketId
        );
        const oldStatus = oldTicket?.status;

        // Update the ticketCounts state
        setTicketCounts((prevCounts) => {
          const newCounts = { ...prevCounts };

          if (oldStatus) {
            newCounts[oldStatus] = (newCounts[oldStatus] || 1) - 1;
          }
          newCounts[statusValue] = (newCounts[statusValue] || 0) + 1;
          return newCounts;
        });

        // Update currentViewTotalTickets and tickets state
        const statusFilter = statusMap[selectedView];
        const ticketWasInView = !statusFilter || oldStatus === statusFilter;
        const ticketIsInView = !statusFilter || statusValue === statusFilter;

        if (ticketWasInView && !ticketIsInView) {
          // Ticket removed from current view
          setTickets((prevTickets) =>
            prevTickets.filter((ticket) => ticket.ticket_id !== ticketId)
          );
          setCurrentViewTotalTickets((prevCount) => prevCount - 1);
        } else if (!ticketWasInView && ticketIsInView) {
          // Ticket added to current view
          // Fetch the full ticket details
          const resTicket = await fetch(`${API_BASE_URL}/tickets/${ticketId}`);
          const fullTicketData = await resTicket.json();
          const fullTicket = fullTicketData.ticket;

          setTickets((prevTickets) => {
            const newTickets = [fullTicket, ...prevTickets];
            // Sort tickets by last_updated in descending order
            newTickets.sort(
              (a, b) => new Date(b.last_updated) - new Date(a.last_updated)
            );
            return newTickets;
          });
          setCurrentViewTotalTickets((prevCount) => prevCount + 1);
        } else if (ticketWasInView && ticketIsInView) {
          // Ticket remains in the current view, update its status
          setTickets((prevTickets) =>
            prevTickets.map((ticket) =>
              ticket.ticket_id === ticketId
                ? { ...ticket, status: statusValue }
                : ticket
            )
          );
        }

        setOpenStatusDropdowns((prevState) => ({
          ...prevState,
          [ticketId]: false,
        }));
      } else {
        console.error("Error updating status:", data.error);
      }
    } catch (err) {
      console.error("Error updating status:", err);
    }
  };

  // Add event listener to detect clicks outside the dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      // For assigned dropdowns
      const assignedDropdownsOpen = Object.keys(openAssignedDropdowns).filter(
        (key) => openAssignedDropdowns[key]
      );

      let clickedInsideAssignedDropdown = false;
      for (const key of assignedDropdownsOpen) {
        const assignedDropdown = document.getElementById(
          `assigned-dropdown-${key}`
        );
        const assignedContainer = document.getElementById(
          `assigned-container-${key}`
        );
        if (
          (assignedDropdown && assignedDropdown.contains(event.target)) ||
          (assignedContainer && assignedContainer.contains(event.target))
        ) {
          clickedInsideAssignedDropdown = true;
          break;
        }
      }

      // For status dropdowns
      const statusDropdownsOpen = Object.keys(openStatusDropdowns).filter(
        (key) => openStatusDropdowns[key]
      );

      let clickedInsideStatusDropdown = false;
      for (const key of statusDropdownsOpen) {
        const statusDropdown = document.getElementById(
          `status-dropdown-${key}`
        );
        const statusContainer = document.getElementById(
          `status-container-${key}`
        );
        if (
          (statusDropdown && statusDropdown.contains(event.target)) ||
          (statusContainer && statusContainer.contains(event.target))
        ) {
          clickedInsideStatusDropdown = true;
          break;
        }
      }

      if (!clickedInsideAssignedDropdown) {
        setOpenAssignedDropdowns({});
      }

      if (!clickedInsideStatusDropdown) {
        setOpenStatusDropdowns({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openAssignedDropdowns, openStatusDropdowns]);

  return (
    <div className="query-container">
      <div className="ticket-list-container">
        {/* Left Sidebar */}
        <div className="query-sidebar-left">
          <div className="query-user-section">
            <p>Hi! {userDetails?.name?.split(" ")[0] || "User"}</p>
            <hr />
          </div>
          <div className="query-views-section query-views-section-left">
            <h3>Views</h3>
            <ul className="query-views-list">
              {views.map((view) => {
                let count;
                if (view === "All tickets") {
                  count = totalTickets;
                } else {
                  const statusValue = statusMap[view];
                  count = ticketCounts[statusValue] || 0;
                }
                const displayCount = count > 100 ? "100+" : count;

                return (
                  <li
                    key={view}
                    className={selectedView === view ? "active" : ""}
                    onClick={() => setSelectedView(view)}
                  >
                    {view} <span>{displayCount}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Main Content */}
        <div className="query-content">
          <h2 className="query-views-title">{selectedView}</h2>
          <div className="filters-container">
            <input
              type="text"
              placeholder="Search..."
              className="search-box"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            {/* Region Filter Dropdown */}
            <div className="region-filter">
              <select
                value={selectedRegion || ""}
                onChange={(e) => setSelectedRegion(e.target.value || null)}
              >
                <option value="">Filter by region</option>
                {regions.map((region) => (
                  <option key={region.region_id} value={region.region_id}>
                    {region.region_name}
                  </option>
                ))}
              </select>
              {selectedRegion && (
                <button
                  className="clear-filter-button"
                  onClick={() => setSelectedRegion(null)}
                >
                  <FontAwesomeIcon icon={faCircleXmark}></FontAwesomeIcon>
                </button>
              )}
            </div>
            {/* My Queries Button */}
            <button
              className={`my-queries-button ${
                isMyQueriesActive ? "active" : ""
              }`}
              onClick={() => setIsMyQueriesActive((prev) => !prev)}
            >
              My Queries
            </button>
          </div>

          <div className="ticket-table">
            <table className="ticket-table-Table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>
                    <input type="checkbox" />
                  </th>
                  <th style={{ width: "10%" }}>ID</th>
                  <th style={{ width: "15%" }}>Query type</th>
                  <th style={{ width: "15%" }}>School</th>
                  <th style={{ width: "20%" }}>Assigned to</th>
                  <th style={{ width: "15%" }}>Status</th>
                  <th style={{ width: "20%" }}>Date</th>
                </tr>
              </thead>
            </table>
            <div id="scrollableTableBody" className="table-body-scroll">
              <table className="ticket-table-Table">
                <tbody>
                  <InfiniteScroll
                    dataLength={tickets.length}
                    next={fetchTickets}
                    hasMore={hasMore}
                    loader={
                      <tr>
                        <td colSpan="7">
                          <h4>Loading...</h4>
                        </td>
                      </tr>
                    }
                    endMessage={
                      <tr>
                        <td colSpan="7">
                          <p style={{ textAlign: "center" }}>
                            <b>No more tickets</b>
                          </p>
                        </td>
                      </tr>
                    }
                    scrollableTarget="scrollableTableBody"
                    component="tbody"
                  >
                    {tickets.map((ticket) => (
                      <tr
                        key={ticket.ticket_id}
                        onClick={() => setSelectedTicket(ticket)}
                      >
                        <td style={{ width: "5%" }}>
                          <input type="checkbox" />
                        </td>
                        <td style={{ width: "10%" }}>#{ticket.ticket_id}</td>
                        <td style={{ width: "15%" }}>{ticket.category}</td>
                        <td style={{ width: "15%" }}>{ticket.school_name}</td>
                        <td style={{ width: "20%" }}>
                          <div
                            id={`assigned-container-${ticket.ticket_id}`}
                            className="assigned-to-container"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenAssignedDropdowns((prevState) => ({
                                ...prevState,
                                [ticket.ticket_id]:
                                  !prevState[ticket.ticket_id],
                              }));
                            }}
                          >
                            <span className="assigned-to-name">
                              {ticket.assigned_to_name || "Unassigned"}
                            </span>
                            {openAssignedDropdowns[ticket.ticket_id] && (
                              <div
                                id={`assigned-dropdown-${ticket.ticket_id}`}
                                className="custom-select-options"
                              >
                                <input
                                  type="text"
                                  placeholder="Search user"
                                  value={searchUser}
                                  onChange={(e) =>
                                    setSearchUser(e.target.value)
                                  }
                                  className="custom-select-input"
                                  onClick={(e) => e.stopPropagation()}
                                />
                                {filteredUsers.map((user) => (
                                  <div
                                    key={user._id}
                                    onClick={() =>
                                      handleAssignChange(
                                        ticket.ticket_id,
                                        user.email
                                      )
                                    }
                                    className="custom-select-option"
                                  >
                                    {user.name}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </td>
                        <td style={{ width: "15%" }}>
                          <div
                            id={`status-container-${ticket.ticket_id}`}
                            className="status-container"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenStatusDropdowns((prevState) => ({
                                ...prevState,
                                [ticket.ticket_id]:
                                  !prevState[ticket.ticket_id],
                              }));
                            }}
                          >
                            <span className="status-name">{ticket.status}</span>
                            {openStatusDropdowns[ticket.ticket_id] && (
                              <div
                                id={`status-dropdown-${ticket.ticket_id}`}
                                className="custom-status-options"
                              >
                                {[
                                  "Open",
                                  "Closed",
                                  "Deleted",
                                  "In Progress",
                                ].map((statusOption) => (
                                  <div
                                    key={statusOption}
                                    onClick={() =>
                                      handleStatusChange(
                                        ticket.ticket_id,
                                        statusOption
                                      )
                                    }
                                    className="custom-status-option"
                                  >
                                    {statusOption}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </td>
                        <td style={{ width: "20%" }}>
                          {new Date(ticket.last_updated).toLocaleDateString(
                            "en-GB"
                          )}{" "}
                        </td>
                      </tr>
                    ))}
                  </InfiniteScroll>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Right Sidebar */}
        <div className="query-sidebar-right">
          <div className="query-user-section">
            <p>Details</p>
            <hr />
          </div>

          {/* Message History */}
          <div className="message-history">
            <h3 className="message-history-title">Message History</h3>
            <div
              className={`messages-container ${
                messageHistory.length === 0 ? "empty" : ""
              }`}
              ref={messageHistoryRef}
              onScroll={handleMessageScroll}
            >
              {isLoadingMessages ? (
                <div className="querybuttonloader"></div>
              ) : messageHistory.length === 0 ? (
                <div className="status-message">No messages found.</div>
              ) : (
                messageHistory.map((msg) => (
                  <div
                    key={msg.message_id}
                    className={`message-item ${
                      msg.sender === userDetails.email ? "sent" : "received"
                    }`}
                  >
                    <div className="message-sender">
                      {msg.sender_name
                        ? msg.sender_name.split(" ")[0]
                        : "Unknown"}
                    </div>
                    <div className="message-content">{msg.content}</div>
                    {msg.document_urls && msg.document_urls.length > 0 && (
                      <div className="message-attachments">
                        {msg.document_urls.map((url, index) => (
                          <a key={index} href={url} target="_blank" rel="noopener noreferrer" className="attachment-link">
                            Attachment {index + 1}
                          </a>
                        ))}
                      </div>
                    )}
                    <div className="message-timestamp">
                      {new Date(msg.sent_at).toLocaleDateString("en-GB")}{" "}
                      {new Date(msg.sent_at).toLocaleTimeString()}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="message-input-section">
              <textarea
                className="message-textbox"
                placeholder="Enter your reply here"
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
              ></textarea>
              <div className="message-options">
                <NeumorphicToggle
                  id="whatsapp-toggle"
                  name="whatsapp"
                  checked={sendViaWhatsApp}
                  onChange={(e) => setSendViaWhatsApp(e.target.checked)}
                  label="WhatsApp"
                />
                <NeumorphicToggle
                  id="mail-toggle"
                  name="mail"
                  checked={sendViaEmail}
                  onChange={(e) => setSendViaEmail(e.target.checked)}
                  label="Mail"
                />
                <button
                  className={`send-button ${isSending ? "sending" : ""}`}
                  onClick={handleSendMessage}
                  disabled={isSending}
                >
                  <div className="svg-wrapper-1">
                    <div className="svg-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          fill="currentColor"
                          d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <span>{isSending ? "Sending..." : "Send"}</span>
                </button>
              </div>
            </div>
          </div>

          {selectedTicket ? (
            <div className="query-views-section">
              <ul className="query-views-list-right">
                <li>
                  <span className="query-tag">Ticket ID : </span>
                  <span className="query-detail">
                    #{selectedTicket.ticket_id}
                  </span>
                </li>
                <li>
                  <span className="query-tag">Full Name : </span>
                  <span className="query-detail">
                    {selectedTicket.user_full_name}
                  </span>
                </li>
                <li>
                  <span className="query-tag">Email : </span>
                  <span className="query-detail">
                    {selectedTicket.user_email}
                  </span>
                </li>
                <li>
                  <span className="query-tag">WhatsApp : </span>
                  <span className="query-detail">
                    {selectedTicket.user_phone}
                  </span>
                </li>
                <li>
                  <span className="query-tag">Preferred Mode : </span>
                  <span className="query-detail">
                    {selectedTicket.user_preferred_mode}
                  </span>
                </li>
                <li>
                  <span className="query-tag">School Name : </span>
                  <span className="query-detail">
                    {selectedTicket.school_name}
                  </span>
                </li>
                <li>
                  <span className="query-tag">User Type : </span>
                  <span className="query-detail">
                    {selectedTicket.user_user_type}
                  </span>
                </li>
                <li>
                  <span className="query-tag">Student Admission Number : </span>
                  <span className="query-detail">
                    {selectedTicket.user_student_admission_no}
                  </span>
                </li>
                <li>
                  <span className="query-tag">Category : </span>
                  <span className="query-detail">
                    {selectedTicket.category}
                  </span>
                </li>
                <li>
                  <span className="query-tag">Description : </span>
                  <span className="query-detail">
                    {selectedTicket.description}
                  </span>
                </li>
                <li>
                  <span className="query-tag">Document : </span>
                  {selectedTicket.document_url ? (
                    <a
                      href={selectedTicket.document_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Document
                    </a>
                  ) : (
                    <span className="query-detail">No document</span>
                  )}
                </li>
              </ul>
            </div>
          ) : (
            <div className="query-views-section">
              <p className="select-ticket">Select a ticket to see details</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QueryDashboard;
