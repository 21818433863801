import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './Auth.css';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const query = new URLSearchParams(location.search);
  const token = query.get('token');


  const handleSubmit = async (e) => {
    e.preventDefault();

   

      
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, { resetToken: token, newPassword });
      setMessage('Password has been reset successfully.');
      setError('');
      setTimeout(() => navigate('/login'), 5000);
    } catch (err) {
      console.error(err);
      setError('Failed to reset password. Please try again.');
      setMessage('');
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleSubmit}>
        <h2>Reset Password</h2>
        {message && <p className="success">{message}</p>}
        {error && <p className="error">{error}</p>}
        <div className="inputbox">
          <input
            type="password"
            className="input"
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="inputbox">
          <input
            type="password"
            className="input"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
