import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import Login from './pages/Login';
import Robotics from './pages/Robotics';
import Register from './pages/Register';
import Profile from './pages/Profile';
import ExcelConversions from './pages/ExcelConversions';
import Schools from './pages/Schools';
import ContactUs from './pages/ContactUs';
import ConfigureRegionSchool from './pages/ConfigureRegionSchool';
import ViewSchools from './pages/ViewSchools';
import ManageClassesBatches from './pages/ManageClassesBatches';
import LessonPlanner from './pages/LessonPlanner';
import ManageUsers from './pages/ManageUsers';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ManageWorkReport from './pages/ManageWorkReport';
import WorkReportRobotics from './pages/WorkReportRobotics';
import RoboticsDateRangeWorkReport from './pages/RoboticsDateRangeWorkReport';
import QueryTicketForm from './pages/QueryTicketForm';
import QueryDashboard from './pages/QueryDashboard';
import Unauthorized from './pages/Unauthorized'; 
import EmployeeWiseRoboticsReport from './pages/EmployeeWiseRoboticsReport';

import AuthProvider, { AuthContext } from './context/AuthContext';
import './App.css';

const PrivateRoute = ({ element: Component, pageId, adminOnly }) => {
  const { auth, userDetails, loading } = useContext(AuthContext);

  if (loading) {
    return null;
  }

  if (!auth) {
    return <Navigate to="/login" />;
  }

  if (adminOnly) {
    // This route is admin-only
    if (userDetails.role === 'ADMIN') {
      return <Component />;
    } else {
      return <Unauthorized />;
    }
  } else {
    if (userDetails.role === 'ADMIN') {
      // Admins have access to all pages
      return <Component />;
    }

    if (userDetails.accessiblePages && userDetails.accessiblePages.includes(pageId)) {
      return <Component />;
    } else {
      return <Unauthorized />;
    }
  }
};


const AppContent = () => {
  const location = useLocation();
  const noSidebarRoutes = ['/login', '/register','/forgot-password','/reset-password','/query-ticket-form'];
  const isNoSidebarRoute = noSidebarRoutes.includes(location.pathname);

  return (
    <div className={`App ${isNoSidebarRoute ? 'no-sidebar' : ''}`}>
      {!isNoSidebarRoute && <Sidebar />}
      <Routes>
        <Route path="/" element={<PrivateRoute element={Home} pageId="home" />} />
        <Route path="/robotics" element={<PrivateRoute element={Robotics} pageId="robotics" />} />
        <Route path="/profile" element={<PrivateRoute element={Profile} pageId="profile" />} />
        <Route path="/excel-conversions" element={<PrivateRoute element={ExcelConversions} pageId="excel-conversions" />} />
        <Route path="/schools" element={<PrivateRoute element={Schools} pageId="schools" />} />
        <Route path="/contact-us" element={<PrivateRoute element={ContactUs} pageId="contact-us" />} />
        <Route path="/configure-region-school" element={<PrivateRoute element={ConfigureRegionSchool} pageId="configure-region-school" />} />
        <Route path="/view-schools" element={<PrivateRoute element={ViewSchools} pageId="view-schools" />} />
        <Route path="/manage-classes-batches" element={<PrivateRoute element={ManageClassesBatches} pageId="manage-classes-batches" />} />
        <Route path="/lesson-planner" element={<PrivateRoute element={LessonPlanner} pageId="lesson-planner" />} />
        <Route path="/manage-users" element={<PrivateRoute element={ManageUsers} pageId="manage-users" adminOnly={true}/>} />
        <Route path="/report-view-work" element={<PrivateRoute element={ManageWorkReport} pageId="report-view-work" />} />
        <Route path="/work-report-robotics" element={<PrivateRoute element={WorkReportRobotics} pageId="work-report-robotics" />} />
        <Route path="/robotics-date-range-work-report" element={<PrivateRoute element={RoboticsDateRangeWorkReport} pageId="robotics-date-range-work-report" />} />
        <Route path="/query-dashboard" element={<PrivateRoute element={QueryDashboard} pageId="query-dashboard" />} />
        <Route path="/employee-wise-robotics-report" element={<PrivateRoute element={EmployeeWiseRoboticsReport} pageId="employee-wise-robotics-report" />} />
        <Route path="/query-ticket-form" element={<QueryTicketForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
