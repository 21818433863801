import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ConfigureRegionSchool.css';
import Modal from './Modal';
import Loader from '../components/Loader';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const ConfigureRegionSchool = () => {
  const [regions, setRegions] = useState([]);
  const [expandedRegion, setExpandedRegion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalPlaceholder, setModalPlaceholder] = useState('');
  const [regionIdForSchool, setRegionIdForSchool] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchRegions = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/regions`);
      const regionsWithSchools = await Promise.all(
        response.data.map(async (region) => {
          const schoolsResponse = await axios.get(`${API_BASE_URL}/schools?region_id=${region.region_id}`);
          return { ...region, schools: schoolsResponse.data };
        })
      );
      setRegions(regionsWithSchools);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching regions:', error);
      setIsLoading(false);
    }
  };

  const toggleRegion = (region_id) => {
    setExpandedRegion(expandedRegion === region_id ? null : region_id);
  };

  const openModal = (title, placeholder, region_id = null) => {
    setModalTitle(title);
    setModalPlaceholder(placeholder);
    setRegionIdForSchool(region_id);
    setIsModalOpen(true);
  };

  const handleSave = async (inputValue) => {
    if (inputValue) {
      try {
        if (modalTitle === 'Add Region') {
          await axios.post(`${API_BASE_URL}/regions`, { region_name: inputValue });
          setMessage('Region created successfully');
        } else if (modalTitle === 'Add School' && regionIdForSchool) {
          await axios.post(`${API_BASE_URL}/schools`, { school_name: inputValue, region_id: regionIdForSchool });
          setMessage('School created successfully');
        }
        fetchRegions();
        setIsModalOpen(false);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          alert(`${modalTitle} not created. ID already exists.`);
        } else {
          console.error('Error saving data:', error);
          setMessage('Error occurred while saving data');
        }
      }
    }
  };

  const handleDeleteRegion = async (region_id) => {
    try {
      await axios.delete(`${API_BASE_URL}/regions/${region_id}`);
      setMessage('Region deleted successfully');
      fetchRegions();
    } catch (error) {
      console.error('Error deleting region:', error);
      setMessage('Error occurred while deleting region');
    }
  };

  const handleDeleteSchool = async (school_id) => {
    try {
      await axios.delete(`${API_BASE_URL}/schools/${school_id}`);
      setMessage('School deleted successfully');
      fetchRegions();
    } catch (error) {
      console.error('Error deleting school:', error);
      setMessage('Error occurred while deleting school');
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="configure-region-school">
      <h1>Configure Regions and Schools</h1>

      {message && (
        <div className="message-box">
          <p>{message}</p>
          <button onClick={() => setMessage('')}>Close</button>
        </div>
      )}

      <button className="add-region-button" onClick={() => openModal('Add Region', 'Enter region name')}>
        Add Region
      </button>
      {isLoading?<Loader/>:
      <div className="region-management">
        <ul>
          {regions.map((region) => (
            <li key={region.region_id}>
              <div className="region-item" onClick={() => toggleRegion(region.region_id)}>
                <span>{region.region_name}</span>
                <div className="region-controls">
                  <button
                    className="add-school-button"
                    onClick={() => openModal('Add School', 'Enter school name', region.region_id)}
                  >
                    Add School
                  </button>
                  <button className="delete-button" onClick={() => handleDeleteRegion(region.region_id)}>Delete</button>
                  <span className="toggle-icon" onClick={() => toggleRegion(region.region_id)}>
                    {expandedRegion === region.region_id ? '▲' : '▼'}
                  </span>
                </div>
              </div>
              {expandedRegion === region.region_id && (
                <ul className="school-list">
                  {region.schools.map((school) => (
                    <li key={school.school_id} className="school-item">
                      {school.school_name}
                      <button className="delete-button" onClick={() => handleDeleteSchool(school.school_id)}>Delete</button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      }

      <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
        title={modalTitle}
        placeholder={modalPlaceholder}
      />
    </div>
  );
};

export default ConfigureRegionSchool;
