// src/components/Sidebar.js
import logo from './logo.png';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import {
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
  faUser,
  faHouse,
  faFileExcel,
  faSchool,
  faAddressBook,
  faRightFromBracket,
  faRobot,
  faUserEdit,
  faClipboardQuestion
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

const Sidebar = () => {
  const { handleLogout, userDetails, loading } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const logoutAndNavigate = () => {
    handleLogout();
    navigate('/login');
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // This will close the sidebar
  };

  if (loading) {
    // Show a loading indicator or return null while loading user details
    return null;
  }

  if (!userDetails || Object.keys(userDetails).length === 0) {
    // User details are not loaded yet
    return null;
  }

  // Define the list of links with their corresponding pageIds and icons
  const links = [
    { to: '/', label: 'Home', pageId: 'home', icon: faHouse },
    { to: '/profile', label: 'Profile', pageId: 'profile', icon: faUser },
    { to: '/excel-conversions', label: 'Excel Conversions', pageId: 'excel-conversions', icon: faFileExcel },
    { to: '/manage-users', label: 'Manage Users', pageId: 'manage-users', icon: faUserEdit,adminOnly: true },
    { to: '/schools', label: 'Schools', pageId: 'schools', icon: faSchool },
    { to: '/robotics', label: 'Robotics', pageId: 'robotics', icon: faRobot },
    { to: '/query-dashboard', label: 'Query Dashboard', pageId: 'query-dashboard', icon: faClipboardQuestion },
    { to: '/contact-us', label: 'Contact Us', pageId: 'contact-us', icon: faAddressBook },
    // Add more links as needed
  ];

  return (
    <>
      <input
        type="checkbox"
        id="checkbox"
        checked={isOpen}
        onChange={toggleSidebar}
        className="checkbox"
      />
      <label htmlFor="checkbox" className="toggle">
        <div className="bar bar--top"></div>
        <div className="bar bar--middle"></div>
        <div className="bar bar--bottom"></div>
      </label>
      <aside className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="header">
          <img src={logo} alt="logo not found" />
          <h1>Techvein</h1>
        </div>
        <nav>
        {links.map((link) => {
          if (link.adminOnly) {
            // Link is admin only
            if (userDetails.role === 'ADMIN') {
              return (
                <Link
                  key={link.to}
                  to={link.to}
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <FontAwesomeIcon icon={link.icon} />
                  <p>{link.label}</p>
                </Link>
              );
            } else {
              return null;
            }
          } else {
            // Not admin only
            if (
              userDetails.role === 'ADMIN' ||
              (userDetails.accessiblePages && userDetails.accessiblePages.includes(link.pageId))
            ) {
              return (
                <Link
                  key={link.to}
                  to={link.to}
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  <FontAwesomeIcon icon={link.icon} />
                  <p>{link.label}</p>
                </Link>
              );
            } else {
              return null;
            }
          }
        })}
        {/* Logout Link */}
        <a onClick={logoutAndNavigate} className="nav-link">
          <FontAwesomeIcon icon={faRightFromBracket} />
          <p>Log out</p>
        </a>
      </nav>

      </aside>
    </>
  );
};

export default Sidebar;
