import React, { useState, useEffect } from "react";
import axios from "axios";
import "./QueryTicketForm.css";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const QueryTicketForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    admission: "",
    school: "",
    queryType: "",
    message: "",
    userType: "",
    prefferedMode: "",
    document: null,
    documentName: "Upload Supporting Document (Optional)",
  });

  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [schoolSearch, setSchoolSearch] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSchools = async () => {
      if (schoolSearch.length >= 2) {
        try {
          const response = await axios.get(`${API_BASE_URL}/search-schools`, {
            params: {
              query: schoolSearch,
            },
          });
          setFilteredSchools(response.data);
        } catch (error) {
          console.error("Error fetching schools:", error);
          setMessage("Failed to fetch schools. Please try again.");
        }
      } else {
        setFilteredSchools([]);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchSchools();
    }, 300); // Debounce by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [schoolSearch]);


  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const fetchSchools = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/view-schools`);
      setSchools(response.data);
      setFilteredSchools(response.data);
    } catch (error) {
      console.error("Error fetching schools:", error);
      setMessage("Failed to fetch schools. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    
    setFormData({
      ...formData,
      document: file,
      documentName: file ? file.name : "Upload Supporting Document", // Update title with file name
    });
  };

  const handleSelectSchool = (schoolId, schoolName) => {
    setFormData({ ...formData, school: schoolId });
    setSchoolSearch(schoolName);
    setIsDropdownOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formPayload = new FormData();
    formPayload.append('name', formData.name);
    formPayload.append('email', formData.email);
    formPayload.append('phone', formData.phone);
    formPayload.append('admission', formData.admission);
    formPayload.append('school', formData.school);
    formPayload.append('queryType', formData.queryType);
    formPayload.append('message', formData.message);
    formPayload.append('userType', formData.userType);
    formPayload.append('prefferedMode', formData.prefferedMode);
  
    if (formData.document) {
      formPayload.append('document', formData.document);
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/submit-query`, formPayload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Query submitted successfully:', response.data);
      setMessage("Query submitted successfully! Ticket ID has been mailed to you.");
    } catch (error) {
        setMessage("Failed to submit the query.Please check document size and other parameters.");
    }
    finally{
      setLoading(false);
    }
  };
  
  

  return (
    <div className="query-ticket-contact-container">
      <div className="query-ticket-contact">
        <h2>Query Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="query-ticket-form-group">
            <div className="query-ticket-input-container">
              <label htmlFor="name">Full name*</label>
              <input
                type="text"
                name="name"
                placeholder="Full name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="query-ticket-input-container">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="query-ticket-input-container">
              <label htmlFor="phone">Whatsapp Number *</label>
              <input
                type="text"
                name="phone"
                placeholder="Whatsapp number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="query-ticket-form-group">
            <div className="query-ticket-input-container">
              <label htmlFor="userType">Select User type *</label>
              <select
                name="userType"
                value={formData.userType}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select User Type
                </option>
                <option value="Employee">Employee</option>
                <option value="Parent">Parent</option>
                <option value="Student">Student</option>
              </select>
            </div>
            <div className="query-ticket-input-container">
              <label htmlFor="prefferedMode">
                Preffered Mode Of Communication *
              </label>
              <select
                name="prefferedMode"
                value={formData.prefferedMode}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Communication Mode
                </option>
                <option value="Whatsapp">WhatsApp</option>
                <option value="Email">Email</option>
              </select>
            </div>
          </div>
          <div className="query-ticket-form-group">
            <div className="query-ticket-input-container">
              <label htmlFor="admission">Student Admission Number *</label>
              <input
                type="text"
                name="admission"
                placeholder="Student Admission Number"
                value={formData.admission}
                onChange={handleChange}
                disabled={formData.userType === "Employee"}
              />
            </div>
            <div className="query-ticket-input-container">
              <label htmlFor="school">Select School *</label>

              <div className="custom-select-container">
              <input
                type="text"
                placeholder="Search School"
                value={schoolSearch}
                onClick={() => setIsDropdownOpen(true)}
                onChange={(e) => setSchoolSearch(e.target.value)}
                className="custom-select-input-form"
              />
              {isDropdownOpen && filteredSchools.length > 0 && (
                <div className="custom-select-options">
                  {filteredSchools.map((school) => (
                    <div
                      key={school.school_id}
                      onClick={() =>
                        handleSelectSchool(school.school_id, school.school_name)
                      }
                      className="custom-select-option"
                    >
                      {school.school_name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            </div>
            <div className="query-ticket-input-container">
              <label htmlFor="queryType">Select Query type *</label>
              <select
                name="queryType"
                value={formData.queryType}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Query Type
                </option>
                <option value="Payment">Payment</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="query-ticket-input-container">
            <label htmlFor="message">Your message</label>
            <textarea
              name="message"
              placeholder="Please include all relevant information"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <div className="query-ticket-input-container">
            <label htmlFor="document" className="file-upload-container">
              <span className="file-upload-title">{formData.documentName}</span>
              <input
                type="file"
                id="document"
                name="document"
                onChange={handleFileChange}
                accept=".pdf,.jpg,.png"
                className="file-upload-input"
              />
            </label>
            <p className="file-upload-instructions">
              Supported formats: PDF or Image files upto 10 MB only.
            </p>
          </div>

          <button type="submit" className="query-ticket-submit-button" disabled={loading}>
            
            {loading ? <><div class="querybuttonloader"></div>Submitting</> : 'Submit Query'}
          </button>
        </form>
      </div>
      {message && (
        <div className="message-box">
          <p>{message}</p>
          <button onClick={() => setMessage("")}>Close</button>
        </div>
      )}
    </div>
  );
};

export default QueryTicketForm;
