import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('token');
    return !!token; // Convert to boolean: true if token exists, false otherwise
  });
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const checkUserDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const { data } = await axios.get(`${API_BASE_URL}/auth/current-user`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserDetails(data);
        console.log('User Details:', data);
        setAuth(true);
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
      setAuth(false);
    } finally{
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    checkUserDetails();
  }, [checkUserDetails]);
    
  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
    setUserDetails({});
  };

    return (
      <AuthContext.Provider value={{ auth, setAuth, userDetails, setUserDetails, handleLogout,loading }}>
        {children}
      </AuthContext.Provider>
    );
};

  

export default AuthProvider;
