import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './EmployeeWiseRoboticsReport.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays, format, formatISO } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { useMediaQuery } from 'react-responsive';
import Loader from '../components/Loader';

const EmployeeWiseRoboticsReport = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(''); // Start with empty string
  const [selectedUserName, setSelectedUserName] = useState('');
  const { userDetails } = useContext(AuthContext);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Fetch users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (userDetails.role !== 'ADMIN') {
          // If not admin, use the logged-in user
          setUsers([userDetails]);
          // Do NOT set selectedUser or selectedUserName
          // User must select their name manually
        } else {
          // If admin, fetch all users who have access to this page
          let url = `${process.env.REACT_APP_API_URL}/users`;
          url += `?accessiblePage=employee-wise-robotics-report`;
          const response = await axios.get(url);
          setUsers(response.data.users);
        }
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };
    fetchUsers();
  }, [userDetails.role, userDetails._id]);

  // Fetch reports
  useEffect(() => {
    if (selectedUser && dateState[0].startDate && dateState[0].endDate) {
      const { startDate, endDate } = dateState[0];
      const formattedStartDate = formatISO(startDate, { representation: 'date' });
      const formattedEndDate = formatISO(endDate, { representation: 'date' });

      setIsLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}/robotics-work-reports-employee-wise`, {
        params: {
          user_id: selectedUser,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        }
      }).then(response => {
        // Sort the reports by date
        const sortedReports = response.data.sort((a, b) => new Date(a.date) - new Date(b.date));
        setReports(sortedReports);
        setIsLoading(false);
      }).catch(error => {
        console.error('Failed to fetch reports:', error);
        setIsLoading(false);
      });
    }
  }, [selectedUser, dateState]);

  // Handlers
  const handleUserChange = (event) => {
    const userId = event.target.value;
    const user = users.find(u => u._id === userId);
    setSelectedUser(userId);
    setSelectedUserName(user ? user.name : '');
  };

  const handleDownloadExcel = () => {
    const worksheetData = [
      ["Employee", selectedUserName || ''],
      [],
      ["Date", "School", "Class", "Batch", "Topic", "Description"],
    ];

    reports.forEach(report => {
      worksheetData.push([
        format(new Date(report.date), 'dd/MM/yyyy'),
        report.school_name,
        report.class_name,
        report.batch_name,
        report.topic_name,
        report.description,
      ]);
    });

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reports');

    XLSX.writeFile(workbook, `Employee_Wise_Report_${selectedUserName}.xlsx`);
  };

  return (
    <div className="employee-wise-robotics-report">
      {message && (
        <div className="employee-wise-robotics-report-message-box">
          <p>{message}</p>
          <button onClick={() => setMessage('')}>Close</button>
        </div>
      )}

      <h1>Employee Wise Report</h1>
      <div className="employee-wise-robotics-report-dropdown-row">
        <select
          value={selectedUser}
          onChange={handleUserChange}
        >
          <option value="">Select Employee</option>
          {users && users.length > 0 && users.map(user => (
            <option value={user._id} key={user._id}>
              {user.name}
            </option>
          ))}
        </select>
        <button onClick={() => setShowDatePicker(!showDatePicker)} className="employee-wise-robotics-report-toggle-date-picker">
          <span><FontAwesomeIcon icon={faCalendarAlt} /></span>
        </button>
      </div>
      <div className="employee-wise-robotics-report-DatePicker">
        {showDatePicker && (
          <DateRangePicker
            onChange={item => setDateState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={isMobile ? 1 : 2}
            ranges={dateState}
            direction="horizontal"
          />
        )}
      </div>
      <div className="employee-wise-robotics-report-buttons-row">
        <button className="employee-wise-robotics-report-export-button" onClick={handleDownloadExcel}>
          <span><FontAwesomeIcon icon={faDownload} /></span>Excel
        </button>
      </div>

      {selectedUser && (
        <div className="employee-wise-robotics-report-table-wrapper">
          {isLoading ? <Loader /> :
            <table className="employee-wise-robotics-report-reports-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>School</th>
                  <th>Class</th>
                  <th>Batch</th>
                  <th>Topic</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td>{format(new Date(report.date), 'dd/MM/yyyy')}</td>
                    <td>{report.school_name}</td>
                    <td>{report.class_name}</td>
                    <td>{report.batch_name}</td>
                    <td>{report.topic_name}</td>
                    <td>{report.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      )}
    </div>
  );
};

export default EmployeeWiseRoboticsReport;
