import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCamera, faE, faEdit } from "@fortawesome/free-solid-svg-icons";
import './Profile.css';

const Profile = () => {
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [userName, setUserName] = useState('John Doe');
  const [userInfo, setUserInfo] = useState({
    email: 'john.doe@example.com',
    phone: '+1 (555) 123-4567',
    address: '123 Main St, Springfield, USA'
  });

  const [tempUserInfo, setTempUserInfo] = useState({ ...userInfo });

  const handleEditInfo = () => {
    setIsEditingInfo(true);
    setTempUserInfo({ ...userInfo });
  };

  const handleSaveInfo = () => {
    setUserInfo({ ...tempUserInfo });
    setIsEditingInfo(false);
  };

  const handleCancelEdit = () => {
    setIsEditingInfo(false);
    setTempUserInfo({ ...userInfo });
  };

  // Document section states
  const [documents, setDocuments] = useState([
    { name: 'Resume.pdf', url: '#' },
    { name: 'ID Card.png', url: '#' }
  ]);

  const handleUploadDocument = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDocuments((prev) => [...prev, { name: file.name, url: URL.createObjectURL(file) }]);
    }
  };

  return (
    <div className="profile-page-container">
      <div className="profile-content">
        <div className="profile-header">
          <div className="profile-pic-container">
            <img 
              src="https://via.placeholder.com/150" 
              alt="Profile" 
              className="profile-pic"
            />
            <div className="camera-icon">
            <FontAwesomeIcon icon={faCamera} />
            </div>
          </div>
          <div className="profile-info">
            <h1 className="profile-name">{userName}</h1>
          </div>
        </div>
        
        <div className="info-section">
          <div className="info-section-header">
            <h2>General Info</h2>
            {!isEditingInfo && (
              <button className="edit-btn" onClick={handleEditInfo}>
                <FontAwesomeIcon icon={faEdit}/>
              </button>
            )}
          </div>
          <div className="info-section-content">
            {isEditingInfo ? (
              <div className="edit-form">
                <label>
                  Email: 
                  <input 
                    type="email" 
                    value={tempUserInfo.email} 
                    onChange={(e) => setTempUserInfo({ ...tempUserInfo, email: e.target.value })}
                  />
                </label>
                <label>
                  Phone: 
                  <input 
                    type="text" 
                    value={tempUserInfo.phone} 
                    onChange={(e) => setTempUserInfo({ ...tempUserInfo, phone: e.target.value })}
                  />
                </label>
                <label>
                  Address:
                  <input 
                    type="text" 
                    value={tempUserInfo.address} 
                    onChange={(e) => setTempUserInfo({ ...tempUserInfo, address: e.target.value })}
                  />
                </label>
                <div className="edit-actions">
                  <button onClick={handleSaveInfo} className="save-btn">Save</button>
                  <button onClick={handleCancelEdit} className="cancel-btn">Cancel</button>
                </div>
              </div>
            ) : (
              <ul className="info-list">
                <li><strong>Email:</strong> {userInfo.email}</li>
                <li><strong>Phone:</strong> {userInfo.phone}</li>
                <li><strong>Address:</strong> {userInfo.address}</li>
              </ul>
            )}
          </div>
        </div>

        <div className="document-section">
          <div className="document-section-header">
            <h2>Documents</h2>
            <label className="upload-label">
              <i className="fas fa-upload"></i> Upload Document
              <input type="file" onChange={handleUploadDocument} style={{ display: 'none' }} />
            </label>
          </div>
          <div className="document-grid">
            {documents.map((doc, index) => (
              <div className="document-item" key={index}>
                <div className="document-viewer">
                  {/* Simple viewer - could be an iframe, object tag, or image depending on file type */}
                  {doc.url.endsWith('.pdf') ? (
                    <iframe 
                      src={doc.url} 
                      title={doc.name} 
                      className="doc-frame"
                    ></iframe>
                  ) : (
                    <img src={doc.url} alt={doc.name} className="doc-image"/>
                  )}
                </div>
                <p className="document-name">{doc.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
