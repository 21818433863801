import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ManageWorkReport.css';

const ManageWorkReport = () =>{

    const navigate=useNavigate();

    const handleCardClick = (cardTitle) => {
        if (cardTitle === "Report") {
          navigate('/work-report-robotics');
        } else if(cardTitle==="Date Range Wise Reports") {
          // Handle other card clicks if necessary
          navigate('/robotics-date-range-work-report')
        }else if(cardTitle==="Employee Wise Report"){
            navigate('/employee-wise-robotics-report')
        }
      };

    const functions = [
        {
            title : "Report",
            description : "Report your work here day wise."
        },
        {
            title : "Date Range Wise Reports",
            description : "Veiw your past reports here either date wise or for a particular range."
        },
        {
            title: "Employee Wise Report",
            description: "View any employee work report here either date wise or for a particular range."
        }
    ];

    return(
        <div className="manage-container-work-report">
            <h1>Report / View your Work</h1>
            <div className="manage-grid-work-report">
                {functions.map((func,index)=>(
                    <div key={index} className="manage-card-work-report" onClick={() => handleCardClick(func.title)}>
                        <h3>{func.title}</h3>
                        <p>{func.description}</p>
                    </div>
                ))}

            </div>

        </div>
    );
};

export default ManageWorkReport;